<template>
  <div>
    <label class="label-modal">{{ Title }}</label>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="MyObject[Field]"
          :rules="RULES"
          :color="$primary"
          readonly
          prepend-icon="access_time"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        :color="$primary"
        v-model="MyObject[Field]"
        format="24hr"
        @input="menu = false"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import CRUD from "@/mixins/_CRUD"
export default {
  mixins: [CRUD],
  props: {
    MyObject: Object,
    RULES: Array,
    Field: String,
    Title: String
  },
  data: () => ({
    menu: false
  }),
  mounted() {},
  created() {},
  computed: {},
  methods: {}
}
</script>
